/* eslint-disable react-hooks/exhaustive-deps */
import  { useEffect } from "react";



const LiveAgent = () => {

  useEffect(() => {
    if(!window.liveagent)
      configureSalesforce();

  },[]);

  const configureSalesforce = () => {
    window.name = 'liveAgentWindow';
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = `https://c.la5-c1cs-ia4.salesforceliveagent.com/content/g/js/54.0/deployment.js`;
    script.async = true;
    document.body.appendChild(script);
  };

  return (<>
  </>)
};

const initializeAgent = (buttonId: string, endpoint: string, deploymentId: string, organizationId: string, contactId: string, accountId: string, chatBOTTranscript: string, outletNumber: string, userName: string) => {
    
  document.querySelector('liveAgentWindow');
  window.liveagent.setName(`${userName} # ${outletNumber}`);

  window.liveagent.addCustomDetail('Generated By', 'Chat BOT', true);
  window.liveagent.addCustomDetail('Platform', 'ChatBot', true).saveToTranscript('Platform');

  window.liveagent.addCustomDetail('ContactId', contactId, true);
  window.liveagent.findOrCreate('Contact').map('Id', 'ContactId', true, true, false).saveToTranscript('ContactId').showOnCreate();

  window.liveagent.addCustomDetail('AccountId', accountId, true);
  window.liveagent.findOrCreate('Account').map('Id', 'AccountId', true, true, false).saveToTranscript('AccountId').showOnCreate();

  window.liveagent.addCustomDetail('Chat BOT Transcript', chatBOTTranscript, true);
  window.liveagent.findOrCreate('LiveChatTranscript').map('Id', 'Chat BOT Transcript', true, true, false).saveToTranscript('ChatBOTTranscript__c').showOnCreate();

  window.liveagent.init(endpoint, deploymentId, organizationId);

    if (!window._laq) {
      window._laq = [];
    }
    window._laq.push(function () {
      window.liveagent.showWhenOffline(
        buttonId, 
        document.getElementById("liveagent_button_offline")
      );
      window.liveagent.showWhenOnline(
        buttonId, 
        document.getElementById("liveagent_button_online")
      );
    });
  };

 export const initializeChat = (buttonId: string, endpoint: string, deploymentId: string, organizationId: string, contactId: string, accountId: string, chatBOTTranscript: string, outletNumber: string, userName: string, sendMessageOffline: ()=>void) => {

  window.liveagent.addButtonEventHandler(buttonId, function (e: any) {
    if (e === window.liveagent.BUTTON_EVENT.BUTTON_AVAILABLE) {
      window.liveagent.startChatWithWindow(buttonId, 'liveAgentWindow')
    }
    
    if (e === window.liveagent.BUTTON_EVENT.BUTTON_UNAVAILABLE) {
      sendMessageOffline()
    }
  });
  initializeAgent(buttonId, endpoint, deploymentId, organizationId, contactId, accountId, chatBOTTranscript, outletNumber, userName);
};


export default LiveAgent;