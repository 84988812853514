
function App() {
  return (
    <div  className="header-container">
        <p className="description-title">myCoke Assistant</p>
    </div>
  );
}

export default App;
