import {  useState } from 'react';
import HeaderComponent from "./components/HeaderComponent";
import ChatComponent from "./components/ChatComponent";

import "./App.css";

function App() {
  
  const [showHeader] = useState<boolean>(false);

  return (
    <div className="app">
      { showHeader &&
        <header>
          <HeaderComponent />
        </header>
      }
      <div>
        <ChatComponent />
      </div>
    </div>
  );
}

export default App;
