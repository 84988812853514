/* eslint-disable react-hooks/exhaustive-deps */
import ReactWebChat, {
  createDirectLine,
  createStyleSet,
  createStore
} from "botframework-webchat";
import { useEffect, useMemo, useState } from "react";
import logo from ".././RedAvatar.svg";
import sendImage from ".././SendImage.svg";
import { Store, AnyAction } from "redux";
import LiveAgent,{initializeChat} from "../liveAgentComponents/LiveAgentChatComponent";

const AUTH_ENDPOINT = `${process.env.REACT_APP_BASE_URL}api/auth/embedded?userId={{USER_ACCOUNT}}`;
const CONV_TIMEOUT = Number(process.env.REACT_APP_CONV_TIMEOUT);
const DEFAULT_PROVIDER = 'mycoke';

type ActivityValue = {
  liveChatUrl: string,
  deploymentId: string,
  refreshmentsId: string
  buttonId: string,
  contactId: string,
  accountId: string,
  chatBOTTranscript: string,
  outletNumber: string,
  userName: string
}
function App() {

  const [directLineToken, setDirectLineToken] = useState<string>();

  const session = new URLSearchParams(window.location.search).get(
    "session"
  ) || "";

  let provider = new URLSearchParams(window.location.search).get(
    "provider"
  ) || DEFAULT_PROVIDER;

  let interval: NodeJS.Timeout;


  useEffect(() => {
    if (session !== null && AUTH_ENDPOINT !== undefined)
      fetch(AUTH_ENDPOINT.replace("{{USER_ACCOUNT}}", session))
        .then((res) => res.text())
        .then((token) => {
          setDirectLineToken(token);
        });
  }, [setDirectLineToken, session]);

  const directLine = useMemo(
    () =>
      directLineToken !== ""
        ? createDirectLine({ token: directLineToken })
        : null,
    [directLineToken]
  );



  const store :Store = 
    createStore({}, ({ dispatch } : AnyAction) => (next : any) => (action : any)  => {
      injectSendButton()
      if (action.type === 'DIRECT_LINE/POST_ACTIVITY') {
        clearTimeout(interval);
        return next(action)

      } else if (action.type === 'DIRECT_LINE/INCOMING_ACTIVITY') {
        if (action.payload.activity.name === "idleWarning") {
          // If the message is related to the first warning, start timeout timer
          startTimeoutTimer();
        } else if (action.payload.activity.name === "idleTimeout" || action.payload.activity.name === "exit") {
          // Stop timer process if user exited or timed out.
          clearInterval(interval);
          // Disable input 
          disableInput();
        } else if(action.payload.activity.text?.includes('[DEBUG]')){
          // If incoming debug message, do not alter timer behavior
        } else if(action.payload.activity.name === "liveAgentChat") {
          const value = action.payload.activity.value as ActivityValue
          if(value){

            let buttonId = value.buttonId
            let endpoint = value.liveChatUrl
            let deploymentId = value.deploymentId
            let organizationId = value.refreshmentsId
            let contactId = value.contactId
            let accountId = value.accountId
            let chatBOTTranscript = value.chatBOTTranscript
            let outletNumber = value.outletNumber
            let userName = value.userName

            initializeChat(buttonId,endpoint,deploymentId,organizationId,contactId,accountId,chatBOTTranscript,outletNumber,userName,sendMessageOffline)
          }
        } else {
          // Normal messages received by bot will reset the warning timer
          startNormalMessageTimer();
        }
      
    }
    if (action.type === 'DIRECT_LINE/CONNECT_FULFILLED') {
      dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'webchat/join',
          value: `{"provider": "${provider}", "sessionId": "${session}"}`,
        }
      });
    }

    return next(action);
  });

  
  const sendMessageOffline = () => {

    store.dispatch({
      type: 'WEB_CHAT/SEND_EVENT',
      payload: {
        name: 'liveAgentOff',
        value: ''
      }
    });
  }

  const injectSendButton = () => {
    const parent = document.getElementsByClassName( 'main' )[0]
    if(parent){
      const child = parent.children[1].getElementsByTagName('svg')[0];
      const svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
      const img = document.createElement("img");
      img.src= sendImage;
      svg.setAttribute('height', '28');
      svg.setAttribute('width', '28');
      img.setAttribute('style','padding-right: 5px');
      svg.appendChild(img);
      if(child)
        child.replaceWith(img);
    }
  }

  const disableInput = () => {

    const parent = document.getElementsByClassName('main')[0];
    if(parent){
      const input = parent.children[0].children[0];
      input.setAttribute('disabled', 'disabled');
    }
  }

  function startNormalMessageTimer(){
    clearInterval(interval);
    interval = setTimeout(() => {
      // Notify bot the user has been inactive
      store.dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'idleWarning',
          value: (CONV_TIMEOUT/60000)
        }
      });
    }, CONV_TIMEOUT/2)
  };

  function startTimeoutTimer(){
    clearInterval(interval);
    interval = setTimeout(() => {
      // Notify bot that the user timed out
      store.dispatch({
        type: 'WEB_CHAT/SEND_EVENT',
        payload: {
          name: 'idleTimeout',
          value: ''
        }
      });
      store.dispatch({
        type: 'WEB_CHAT/SEND_MESSAGE_BACK',
        payload: {
          text: '',
          value: 'Timeout'
        }
      });
    }, CONV_TIMEOUT/2)
  };

  // const DEFAULT_ACCENT = '#000000';
  const styleSet = createStyleSet({
    // Basic
    backgroundColor: '#ecf0f1',
  });

  const styleOptions = {
    // Avatar
    botAvatarImage: logo,
    botAvatarInitials: '',
    hideUploadButton: true,
    autoScrollSnapOnActivity: true
  };
 

  return (
    <div className="webChat">
      {directLine && (
        <ReactWebChat
          directLine={directLine}
          styleSet={styleSet}
          styleOptions={styleOptions}
          store={store}
        />
      )}
        <LiveAgent  />
    </div>
  );
}

export default App;

